import React, { useState } from 'react';

import { Dialog } from "@progress/kendo-react-dialogs";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import { TextArea } from "@progress/kendo-react-inputs";
import { Button } from '@progress/kendo-react-buttons';
import { Checkbox } from "@progress/kendo-react-inputs";

import { completeCall } from '../../../core/services/workOrder.service';





export const CallStatusCommentModal = ({ closeModal, setLoading, workOrderId, userId, fetchWorkOrder, workOrders, setWorkOrders, workOrderNumber }) => {
    const [success, setSuccess] = useState(false);
    const [comment, setComment] = useState('');
    const [callSuccess, setCallSuccess] = useState(false);

    const handleForm = (evt) => {
        setComment(evt.value);
    };

    const handleCheckbox = (evt) => {
        setCallSuccess(evt.value);
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        try {
            setLoading(true);
            await completeCall({ userId, workOrderId, comment, callSuccess });
            setLoading(false);
            setSuccess(true);
            const swo = await fetchWorkOrder(workOrderId);
            const woIndex = workOrders.findIndex(wo => wo.id === workOrderId);
            setWorkOrders(prev => {
                prev[woIndex].bookCode = swo.bookCode
                return prev;
            });
            setTimeout(() => closeModal(), '800');
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Dialog class="k-dialog" title={`Update Call Status for: ${workOrderNumber}`} onClose={closeModal}>
                <div id='confirm_appointment'>
                    <form className='confirm-details-contact-container'>
                        <FieldWrapper style={{width: '100%'}}>
                            <Label>Comment:</Label>
                            <div className={"k-form-field-wrap"}>
                            <TextArea
                                required={!callSuccess}
                                style={{ height:134 }}
                                disabled={false}
                                name={'additionalNotes'}
                                onChange={handleForm}
                                value={comment}
                                rows={7}
                            />
                            </div>
                        </FieldWrapper>
                        <div class="k-d-flex k-align-items-center">
                            <Checkbox value={callSuccess} onChange={handleCheckbox} label={'Call was successful'} style={{ margin: '10px' }}/>
                            <Button disabled={(!comment.length > 0 && !callSuccess) || success} style={{ margin: '10px' }} primary={true} onClick={handleSubmit}>Submit</Button>
                        </div>
                    </form>
                    { success &&   <div className="fullscreen-overlay">
                                        <div className="overlay-content">
                                            <h1>Call Status Updated</h1>
                                        </div>
                                    </div>
                    }
                </div>
            </Dialog>
        </>
    );
}