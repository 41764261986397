import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Loader from '../loader/index';
import { WorkOrderDetails } from './detailsModal';
import { CallStatusCommentModal } from './CallStatusCommentModal';

import { Card, CardHeader, CardTitle, CardSubtitle, CardBody, CardFooter } from '@progress/kendo-react-layout';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';

import { getCsrPortalWorkOrders, getWorkOrderDetails } from '../../../core/services/workOrder.service';
import { getAppointmentByWorkOrder } from '../../../core/services/appointment.service';
import { getAppointmentAvailability } from '../../../core/services/appointment.service';
import { getCurrentUser } from '../../../core/services/auth.service';
import { getUserByUsername } from '../../../core/services/user.service';

import WorkOrdersGrid from './grid';

import AppContext from '../../../core/context/app.context';

import './index.css';

const QueryComponent = () => {
  const history = useHistory();
  const context = useContext(AppContext);
  const [availabilityData, setAvailabilityData] = useState([]);
  const [searchTerm, setSearchTerm] = useState();
  const [scheduleNow, setScheduleNow] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchInputValid, setSearchInputValid] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [statusCommentModal, setStatusCommentModal] = useState(false);
  const [possibleWorkOrders, setPossibleWorkOrders] = useState([]);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [workOrders, setWorkOrders] = useState([]);
  const [loadingProjectWorkOrders, setLoadingProjectWorkOrders] = useState(true);

  useEffect(() => {
    if(!context || !context.currentProject) history.push('/dashboard');
    const searchParams = new URLSearchParams(window.location.search);
    const phoneNumber = searchParams.get('q');
    if (phoneNumber && phoneNumber.length === 10) {
      const maskedNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      setSearchInput(maskedNumber);
      setSearchInputValid(true);
    } else {
      fetchUser();
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        if (user.userProjects && user.userProjects.items && user.userProjects.items.length > 0) {
          let tempWorkOrders = [];
          for (const project of user.userProjects.items) {
            if (project.projectConfiguration) {
              const projectConfiguration = JSON.parse(project.projectConfiguration);
              if (projectConfiguration.additionalFeatures && projectConfiguration.additionalFeatures.csrPortal) {
                const results = await fetchAllProjectWorkOrders(project.id);
                tempWorkOrders = [...tempWorkOrders, ...results.map(x => ({ ...x, projectName: project.projectName}))];
              }
            }
          }
          setWorkOrders(tempWorkOrders);
          setLoadingProjectWorkOrders(false);
        }
      }
    }
    fetchData();
  }, [user]);

  const fetchUser = async () => {
    const cogUser = await getCurrentUser();
    if (!cogUser) return history.push('/');
    const appUser = await getUserByUsername(cogUser.username);
    setUser(appUser);
    return appUser.tenant.id;
  }

  const fetchAllProjectWorkOrders = async (projectId) => {
    let offset = 0;
    let promiseChain = [];
    const pagesToFetch = Math.ceil(context.workOrderStatusCounts.Total / 1000);
    for(let i = 1; i <= pagesToFetch; i++){
      promiseChain.push(getCsrPortalWorkOrders(projectId, offset));
      offset += 1000;
    }
    const dbRes = await Promise.all(promiseChain);
    const results = dbRes.flat().map(dataItem => {
      let address = '';
      if(dataItem.street) address += dataItem.street;
      if(dataItem.street2) address += ('\n' + dataItem.street2);
      if(dataItem.street3) address += ('\n' + dataItem.street3);
      dataItem.address = address;
      if(dataItem.customDataFields){
        for(let item of dataItem.customDataFields){
          if(item.key === 'Quantity'){
            dataItem.quantity = item.value;
          } else if(item.key === 'Enrollment_Date') {
            dataItem.dateEnrolled = item.value;
          } else if(item.key === 'WorkOrderNumberCopy'){
            dataItem.workOrderNumberCopy = item.value;
          }
        }
      }
      return dataItem;
    });
    return results;
  }

  const searchWorkOrders = ( evt ) => {
    evt.preventDefault();
    const input = searchInput.toLocaleLowerCase();
    const results = workOrders.filter(workOrder => {
      const words = Object.values(workOrder);
      const concatenatedWords = words.join(' ').toLocaleLowerCase();
      return concatenatedWords.includes(input);
    });
    setPossibleWorkOrders(results);
  }

  const clearWorkOrders = () => {
    setSearchInput('');
    setPossibleWorkOrders([]);
  }

  const handleSearchInput = ({ value }) => {
    if(value.length > 0) setSearchInputValid(true);
    setSearchInput(value);
  }

  const closeDetailsModal = () => {
    setScheduleNow(false)
    setDetailsModalOpen(false);
  }

  const fetchWorkOrder = async (woID) => {
    const swo = await getWorkOrderDetails(woID);
    const swoa = await getAppointmentByWorkOrder(swo.project.id, woID);
    const appointmentId = swoa ? swoa.id : null;
    setSelectedWorkOrder({ ...swo, appointmentId });
    return swo;
  }

  const initializeSearchDates = ( inputDate = new Date() ) => {
      const startDate = new Date(inputDate);
      const dayOfWeek = inputDate.getDay();
      const diffToMonday = (dayOfWeek + 7) % 7;
      startDate.setDate(startDate.getDate() - diffToMonday);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 7);
      return [startDate, endDate]
  }

  const fetchAvailability = async ({ workOrderId, startDate, endDate }) => {
    if(!startDate && !endDate){
      const [ start, end ] = initializeSearchDates();
      startDate = start;
      endDate = end;
    }
    const data = await getAppointmentAvailability({
      workOrderId: workOrderId, startDate, endDate
    });
    const transformedData = data.map( item => {
      return ({
        start: new Date(item.start),
        end: new Date(item.end),
        title: `${item.slotsOpen} slots available`,
        isAllDay: false,
        description: item.slotsOpen > 0 ? 'Available' : 'Booked',
        recurrenceRule: '',
        id: Math.random()
      })
    });
    setAvailabilityData(transformedData)
  }

  const handleOpenWODetails = async (workOrderId) => {
    setLoading(true);
    await fetchAvailability({ workOrderId });
    await fetchWorkOrder(workOrderId);
    setDetailsModalOpen(true);
    setLoading(false);
  }

  const triggerScheduleNow = async (workOrderId) => {
    setLoading(true);
    setScheduleNow(true);
    await fetchAvailability({ workOrderId });
    await fetchWorkOrder(workOrderId);
    setDetailsModalOpen(true);
    setLoading(false);
  }

  const openUpdateCallStatusModal = (wo) => {
    setStatusCommentModal(true);
    if(wo.id)setSelectedWorkOrder(wo);
  }

  const closeUpdateCallStatusModal = () => {
    setStatusCommentModal(false);
    if(!detailsModalOpen) setSelectedWorkOrder(null);
  }

  return (
    <div style={{ padding: '20px' }}>
      <form onSubmit={searchWorkOrders} className={'csr-header-bar'}>
        <h1 className={'csr-portal-title'}>Quick Filter:</h1>
        <div>
          <Input value={searchInput} onChange={handleSearchInput} />
          <Button style={{ marginLeft: '20px', boxShadow: 'none' }} disabled={!searchInputValid} primary={true} onClick={searchWorkOrders}>Search</Button>
        </div>
        <Button type='button' onClick={clearWorkOrders}>Clear</Button>
      </form>
      {
        possibleWorkOrders.map((wo, index) => {
          return (
            <Card key={index} style={{ borderColor: '#00000069', borderWidth: '1px', marginBottom: '20px' }}>
              <CardHeader>
                <CardTitle>
                  <span className='k-icon k-font-icon k-i-user k-icon-xs' />
                  {wo.customerName}
                </CardTitle>
                <CardSubtitle>
                  Project: {wo.projectName}
                </CardSubtitle>
                <CardSubtitle>
                  Work Order Number: ({wo.workOrderNumber})
                </CardSubtitle>
              </CardHeader>
              <CardBody>
                <p className='card-body-info-header'>Address</p>
                {wo.street && <p>{wo.street}</p>}
                {wo.street2 && <p>{wo.street2}</p>}
                {wo.street3 && <p>{wo.street3}</p>}
                {wo.city && wo.stateCode && wo.zipCode && (
                  <p>{wo.city}, {wo.stateCode} {wo.zipCode}</p>
                )}
                {(wo.mobilePhoneNumber || wo.homePhoneNumber || wo.businessPhoneNumber) && (
                  <>
                    <div className='card-body-separator'></div>
                    <p className='card-body-info-header'>Phone Numbers</p>
                    {wo.mobilePhoneNumber && <p>Mobile: {wo.mobilePhoneNumber}</p>}
                    {wo.homePhoneNumber && <p>Home: {wo.homePhoneNumber}</p>}
                    {wo.businessPhoneNumber && <p>Business: {wo.businessPhoneNumber}</p>}
                  </>
                )}
              </CardBody>
              <CardFooter>
                <Button onClick={() => handleOpenWODetails(wo.id)} primary={true} style={{ marginRight: '10px' }}>View WO Details</Button>
                <Button onClick={() => triggerScheduleNow(wo.id)}>Schedule Appointment</Button>
                <Button onClick={() => openUpdateCallStatusModal(wo)}>Update Call Status</Button>
              </CardFooter>
            </Card>
          )
        })
      }
      {detailsModalOpen && (
        <WorkOrderDetails
          timeZone={context.currentProject.projectTimeZone}
          setLoading={setLoading}
          user={user}
          fetchWorkOrder={fetchWorkOrder}
          selectedWorkOrder={selectedWorkOrder}
          availabilityData={availabilityData}
          fetchAvailability={fetchAvailability}
          closeModal={closeDetailsModal}
          scheduleNow={scheduleNow}
          resetScheduleNow={() => setScheduleNow(false)}
          openUpdateCallStatusModal={openUpdateCallStatusModal}
        />
      )}
      {statusCommentModal && (
        <CallStatusCommentModal
          fetchWorkOrder={fetchWorkOrder}
          workOrderId={selectedWorkOrder.id}
          workOrderNumber={selectedWorkOrder.workOrderNumber}
          userId={user.id}
          setLoading={setLoading}
          closeModal={closeUpdateCallStatusModal}
          workOrders={workOrders}
          setWorkOrders={setWorkOrders}
        />
      )}
      <div className={'loader-wrapper'}>
        <Loader loading={loading || loadingProjectWorkOrders} />
      </div>
      { workOrders && workOrders.length > 0 && !loadingProjectWorkOrders && (
        <WorkOrdersGrid
          data={workOrders}
          handleOpenWODetails={handleOpenWODetails}
        />
      )}
    </div>
  );
};

export default QueryComponent;

